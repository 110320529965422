import './App.css';
import {
  AboutMe,
  Contact,
  Landing,
  Navbar,
  Portfolio,
  Skills,
} from './screens';

const App = () => {
  return (
    <div>
      <Navbar />
      <Landing />
      <AboutMe />
      <Skills />
      <Portfolio />
      <Contact />
    </div>
  );
};

export default App;
