import { useState, useEffect } from 'react';
import './Navbar.css';
import hamburgerMenu from './hamburger-menu.svg';

const Navbar = () => {
  const [showLinks, setShowLinks] = useState(true);
  const [showBtn, setShowBtn] = useState(false);

  const handleClick = () => {
    setShowLinks(!showLinks);
  };

  window.addEventListener('resize', (e) => {
    if (e.target.innerWidth > 768) {
      setShowBtn(false);
      setShowLinks(true);
    } else {
      setShowBtn(true);
      setShowLinks(false);
    }
  });

  useEffect(() => {
    if (window.innerWidth > 768) {
      setShowBtn(false);
      setShowLinks(true);
    } else {
      setShowBtn(true);
      setShowLinks(false);
    }
  }, []);

  return (
    <div className="navbar-container">
      <div className="navbar-name">
        <div>SK</div>
        {showBtn && (
          <img alt="hamburger menu" src={hamburgerMenu} onClick={handleClick} />
        )}
      </div>
      {showLinks && (
        <div className="navbar-links-container">
          <a href="#aboutme">About Me</a>
          <a href="#skills">Skills</a>
          <a href="#projects">Projects</a>
          <a href="#contact">Contact</a>
        </div>
      )}
    </div>
  );
};

export default Navbar;
