import './Contact.css';

const Contact = () => {
  return (
    <div id="contact" className="contact-container">
      <a
        href="https://www.linkedin.com/in/samuel-ki/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="/images/linkedin.svg" alt="linkedin logo" />
      </a>
      <a
        href="https://github.com/samuelki"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="/images/github.svg" alt="github logo" />
      </a>
      <a
        href="mailto:samuelki62@gmail.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="/images/email.svg" alt="email logo" />
      </a>
    </div>
  );
};

export default Contact;
