import js from './svg/js.svg';
import ts from './svg/ts.svg';
import react from './svg/react.svg';
import angular from './svg/angular.svg';
import html from './svg/html.svg';
import css from './svg/css.svg';
import csharp from './svg/csharp.svg';
import dotnet from './svg/dotnet.svg';
import sql from './svg/sql.svg';
import git from './svg/git.svg';

export const techs = [
  { name: 'JavaScript', image: js },
  { name: 'TypeScript', image: ts },
  { name: 'React', image: react },
  { name: 'Angular', image: angular },
  { name: 'HTML', image: html },
  { name: 'CSS', image: css },
  { name: 'C#', image: csharp },
  { name: '.NET', image: dotnet },
  { name: 'SQL', image: sql },
  { name: 'Git', image: git },
];
