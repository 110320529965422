import './AboutMe.css';
import headshot from './headshot.jpeg';

const AboutMe = () => {
  return (
    <div id='aboutme' className='aboutme-container'>
      <img src={headshot} alt='aboutme' />
      <div>
        <p>
          Hello! My name is Samuel Ki and I am currently located in Seattle, WA.
          I love to code and have always been passionate about technology. I
          graduated from UCLA majoring in Sociology. Shortly after graduating,
          I've completed a 20-week software development program with Coding Dojo
          focused on learning two full stacks: Python (Django, Flask) and MEAN
          (MongoDB, Express.js, Angular, Node.js). Learning so much in such a
          short amount of time conditioned me to not be afraid of learning new
          languages/technologies. I'm always looking for my next challenge!
        </p>
      </div>
    </div>
  );
};

export default AboutMe;
