export const projects = [
  {
    title: 'DatingApp',
    description:
      'Full-stack dating web application built using .NET 7.0, Angular 16, Bootstrap 5, PostgreSQL. Users are able to register/login, edit their profile, upload images, and like/message other users.',
    image: '/images/datingapp.png',
    url: 'https://github.com/samuelki/datingapp',
  },
  {
    title: 'Realtor Clone',
    description:
      'Real estate listing website based on Realtor.com built using React, Tailwind CSS, and Firebase',
    image: '/images/realtorclone.png',
    url: 'https://github.com/samuelki/realtor-clone',
  },
  {
    title: 'DevMeet',
    description:
      'Full-stack Social Networking web application for developers that includes features such as login/registration, authentication, profiles, and forum posts/comments/likes. Built using the MERN stack (Node.js, Express, React/Redux, MongoDB).',
    image: '/images/devmeet.png',
    url: 'https://github.com/samuelki/devmeet',
  },
  {
    title: 'JSBook',
    description:
      'Dynamic JavaScript coding environment drawing inspirations from CodePen and Jupyter Notebook, where you have a markdown editor and a code editor being executed in an adjacent preview window. Built using TypeScript, React, and Redux.',
    image: '/images/jsbook.png',
    url: 'https://github.com/samuelki/jsbook',
  },
  {
    title: 'CinemaDB',
    description:
      'Movie library web application using The Movie Database API drawing inspirations from IMDb. Search for movies, explore popular/top rated/upcoming movies, view the trailer in a modal, and view the cast of the movie in detail. Built using JavaScript, React, and Redux.',
    image: '/images/cinemadb.png',
    url: 'https://github.com/samuelki/cinemadb',
  },
];
