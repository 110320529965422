import { Typewriter, AnimatedBackground } from '../components';
import './Landing.css';

let titlesArray = [
  ' ',
  'Software Engineer',
  'Front-end Developer',
  'Back-end Developer',
  'Full-stack Developer',
];

const Landing = () => {
  return (
    <AnimatedBackground>
      <div className="landing-type">
        <div>Samuel Ki</div>
        <Typewriter data={titlesArray} />
      </div>
    </AnimatedBackground>
  );
};

export default Landing;
