import { useEffect } from 'react';
import './Typewriter.css';

/**
 *
 * @param {Array} data - Array[]: Array of strings to be typed
 * @param {Number} typeSpeed - Number: speed of typing in milliseconds
 * @param {Number} delay - Number: delay between each message in milliseconds
 */

const Typewriter = ({ data = [], typeSpeed = 100, delay = 2000 }) => {
  useEffect(() => {
    let characterPos = 0;
    let msgBuffer = '';
    let msgIndex = 0;
    let msgDelay;

    const startTyping = () => {
      let id = document.getElementById('typing-text');

      if (characterPos !== data[msgIndex].length) {
        msgBuffer += data[msgIndex].charAt(characterPos);
        id.value = msgBuffer + '_';
        msgDelay = typeSpeed;
      } else {
        msgDelay = delay;
        msgBuffer = '';
        characterPos = -1;
        if (msgIndex !== data.length - 1) {
          msgIndex++;
        } else {
          msgIndex = 0;
        }
      }
      characterPos++;
      setTimeout(startTyping, msgDelay);
    };

    startTyping();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <textarea id="typing-text" readOnly></textarea>;
};

export default Typewriter;
