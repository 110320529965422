import React from 'react';

import { ReactComponent as Basketball } from './svg/basketball.svg';
import { ReactComponent as Cloud } from './svg/cloud.svg';
import { ReactComponent as Code } from './svg/code.svg';
import { ReactComponent as Coffee } from './svg/coffee.svg';
import { ReactComponent as Css } from './svg/css.svg';
import { ReactComponent as Dotnet } from './svg/dotnet.svg';
import { ReactComponent as Flask } from './svg/flask.svg';
import { ReactComponent as Game } from './svg/game.svg';
import { ReactComponent as Git } from './svg/git.svg';
import { ReactComponent as Headphones } from './svg/headphones.svg';
import { ReactComponent as Html } from './svg/html.svg';
import { ReactComponent as Js } from './svg/js.svg';
import { ReactComponent as Keyboard } from './svg/keyboard.svg';
import { ReactComponent as Movies } from './svg/movies.svg';
import { ReactComponent as ReactSVG } from './svg/react.svg';
import { ReactComponent as Sql } from './svg/sql.svg';
import { ReactComponent as Web } from './svg/web.svg';
import { ReactComponent as Weight } from './svg/weight.svg';
import { ReactComponent as Python } from './svg/python.svg';
import { ReactComponent as Typescript } from './svg/typescript.svg';

import './AnimatedBackground.css';

const AnimatedBackground = ({ children }) => {
  let arr = [];
  let qty = 20;

  for (let i = 0; i < qty; i++) {
    arr.push(i);
  }

  const layers = arr.map((i) => {
    return (
      <div key={i} className="animated-row">
        <div>
          <Js />
          <Typescript />
          <Python />
          <Dotnet />
          <ReactSVG />
          <Keyboard />
          <Git />
          <Sql />
          <Coffee />
          <Html />
          <Flask />
          <Weight />
          <Web />
          <Cloud />
          <Code />
          <Css />
          <Movies />
          <Game />
          <Basketball />
          <Headphones />
        </div>

        <div>
          <Js />
          <Typescript />
          <Python />
          <Dotnet />
          <ReactSVG />
          <Keyboard />
          <Git />
          <Sql />
          <Coffee />
          <Html />
          <Flask />
          <Weight />
          <Web />
          <Cloud />
          <Code />
          <Css />
          <Movies />
          <Game />
          <Basketball />
          <Headphones />
        </div>
      </div>
    );
  });

  return (
    <section className="animated-section">
      {layers}
      {children}
    </section>
  );
};

export default AnimatedBackground;
